import React from 'react';
const SketchComponent = React.lazy(() => import('modules/sketch/index'));

const routers = [
	{
		id: 'sketching',
		path: '/',
		component: SketchComponent,
		exact: true,
	},
];
export default routers;
