import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import routers from 'routers';

const App = props => {
	return (
		<Router basename='/'>
			<Suspense fallback={<div></div>}>
				{routers.map((route, index) => (
					<Route key={route.id} {...route} />
				))}
			</Suspense>
		</Router>
	);
};

export default App;
